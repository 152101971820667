@font-face {
	font-family: Helvetica Neue;
	src: url(./fonts/HelveticaBold.ttf);
}

html,
body {
	padding: 0;
	margin: 0;

	// Color
	color: white;
	background-color: hsl(0deg 0% 10%);

	// Text
	font-family: "Helvetica Neue", serif, sans-serif;
}

a {
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
}
